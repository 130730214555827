const tableDatas = {
  socmedEngagementFields: [
    { key: 'num', label: '#' },
    { key: 'date', sortable: true },
    'post', 'reach', 'like', 'comment', 'share'
  ],
  socmedEngagementDatas: [
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
    {
      date: '2020-11-17',
      post: { type: 'up', value: 395 },
      reach: { type: 'down', value: 100 },
      like: { type: 'down', value: 50 },
      comment: { type: 'up', value: 400 },
      share: { type: 'up', value: 50 },
    },
  ],
  
  closingFields: [
    { key: 'num', label: '#' },
    'customer_name', 'submitted_date', 'close_date', 'value', 'note'
  ],
  closingDatas: [
    {
      customer_name: 'Ms. Mega Murdiana',
      submitted_date: '2020-11-17',
      close_date: '2020-11-17',
      value: 10000,
      note: 'Sudah Transfer',
    },
    {
      customer_name: 'Mr. Galih Kusuma',
      submitted_date: '2020-11-17',
      close_date: '2020-11-17',
      value: 50000,
      note: 'Sudah Transfer',
    },
    {
      customer_name: 'Mr. Iman Nur',
      submitted_date: '2020-11-17',
      close_date: '2020-11-17',
      value: 33000,
      note: 'Sudah Transfer',
    },
  ],
  
  sourceMentionFields: [
    { key: 'num', label: '#' },
    { key: 'username', sortable: true },
    { key: 'mention', sortable: true },
    { key: 'reach', sortable: true },
    { key: 'influence_value', sortable: true },
  ],
  sourceMentionDatas: [
    { username: { name: '@rancangmebel', type: 'facebook' }, mention: 20, reach: 1500, influence_value: 3 },
    { username: { name: '@kemilaumarmer', type: 'instagram' }, mention: 20, reach: 1500, influence_value: 1 },
    { username: { name: '@rancangmebel', type: 'twitter' }, mention: 20, reach: 1500, influence_value: 2 },
  ],
  
  latestClosingFields: [
    { key: 'num', label: '#' },
    'customer_name', 'submitted_date', 'close_date', 'mobile_phone', 'note'
  ],
  latestClosingDatas: [
    {
      customer_name: 'Ibu Mega Murdiana	',
      submitted_date: '2020-06-05',
      close_date: '2020-07-05',
      mobile_phone: '+62 8767 9897 7383',
      note: 'Closing',
    },
    {
      customer_name: 'Ibu Yosi Yuniar	',
      submitted_date: '2020-06-05',
      close_date: '2020-07-05',
      mobile_phone: '+62 8767 9897 7383',
      note: 'Closing',
    },
    {
      customer_name: 'Bpk Fauzi',
      submitted_date: '2020-06-05',
      close_date: '2020-07-05',
      mobile_phone: '+62 8767 9897 7383',
      note: 'Closing',
    },
    {
      customer_name: 'Bpk Fajar',
      submitted_date: '2020-06-05',
      close_date: '2020-07-05',
      mobile_phone: '+62 8767 9897 7383',
      note: 'Closing',
    },
    {
      customer_name: 'Ibu Anggraeni',
      submitted_date: '2020-06-05',
      close_date: '2020-07-05',
      mobile_phone: '+62 8767 9897 7383',
      note: 'Closing',
    },
    {
      customer_name: 'Ibu Anggraeni',
      submitted_date: '2020-06-05',
      close_date: '2020-07-05',
      mobile_phone: '+62 8767 9897 7383',
      note: 'Closing',
    },
  ],

  crmLeadsFields: [
    { key: 'num', label: '#' },
    { key: 'date', sortable: true },
    { key: 'name', sortable: true },
    { key: 'source', sortable: true },
    'contact', 'city',
    { key: 'statusPriority', label: 'Status/Priority' },
    'note', 'action',
  ],
  crmLeadsDatas: [
    {
      date: '2020-06-05',
      name: 'Ibu Mega Murdiana',
      source: 'Instagram',
      contact: { email: 'mega@gmail.com', phone: ' +62 736 3738 3838' },
      city: 'Kuningan, South Jakarta',
      statusPriority: { status: 'Open', priority: 'Hot' },
      note: ''
    },
    {
      date: '2020-06-05',
      name: 'Bpk Iwan Kurniawan',
      source: 'Instagram',
      contact: { email: 'mega@gmail.com', phone: ' +62 736 3738 3838' },
      city: 'Kuningan, South Jakarta',
      statusPriority: { status: 'Open', priority: 'Hot' },
      note: ''
    },
    {
      date: '2020-06-05',
      name: 'Ibu Yosi Yuniar',
      source: 'Instagram',
      contact: { email: 'mega@gmail.com', phone: ' +62 736 3738 3838' },
      city: 'Kuningan, South Jakarta',
      statusPriority: { status: 'Open', priority: 'Hot' },
      note: ''
    },
  ],

  leadSummaryFields: [
    { key: 'num', label: '#' },
    'leads',
    'total',
    { key: 'increase', label: 'Increase (%)' },
    { key: 'conversion', label: 'Conversion (%)' },
    'note',
  ],
  leadSummaryDatas: [
    { leads: 'Submitted Leads', total: 395, increase: 10, conversion: 5, note: 'Closing' },
    { leads: 'Followed Up', total: 395, increase: 10, conversion: 5, note: 'Closing' },
    { leads: 'Closed Leads', total: 395, increase: 10, conversion: 5, note: 'Closing' },
    { leads: 'Hot', total: 395, increase: 10, conversion: 5, note: 'Closing' },
    { leads: 'Medium', total: 395, increase: 10, conversion: 5, note: 'Closing' },
    { leads: 'Cold', total: 395, increase: 10, conversion: 5, note: 'Closing' },
    { leads: 'Default', total: 395, increase: 10, conversion: 5, note: 'Closing' },
  ]
}
export default tableDatas