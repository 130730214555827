<template>
  <div class="content-wrapper">
    <bo-page-header></bo-page-header>
    <div class="content pt-0">
      <b-row>
        <b-col md="4">
          <b-card>
            <div class="wrap_sosmed_insight">
              <div class="ic_sosmed bg_ig "><i class="icon-instagram"></i></div>
            </div>
            <div class="wrap_text_prev">
              <h3>Follower</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Total</h2>
                    <p>5,297</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Gain</h2>
                    <p>227</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Lost</h2>
                    <p>43</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap_text_prev">
              <h3>Following</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Total</h2>
                    <p>792</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>New</h2>
                    <p>67</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Unfollowed</h2>
                    <p>25</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap_text_prev">
              <h3>Detail</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Friend</h2>
                    <p>3,372</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Fans</h2>
                    <p>67</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Not Folback</h2>
                    <p>150</p>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card>
            <div class="wrap_sosmed_insight">
              <div class="ic_sosmed bg_fb "><i class="icon-facebook"></i></div>
            </div>
            <div class="wrap_info_connect">
              <h3>Connect Facebook</h3>
            </div>
            <a href="" class="btn_connect">Manage Facebook Connection</a>
            <div class="note_important">
              <p>
                Monitool tidak mengumpulkan data pribadi apapun. Integrasi Anda tidak akan pernah digunakan
                untuk mengelola atau memposting ke halaman facebook Anda.
              </p>
            </div>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card>
            <div class="wrap_sosmed_insight">
              <div class="ic_sosmed bg_tw "><i class="icon-twitter"></i></div>
            </div>
            <div class="wrap_text_prev">
              <h3>Follower</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Total</h2>
                    <p>963</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Gain</h2>
                    <p>166</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Lost</h2>
                    <p>15</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap_text_prev">
              <h3>Following</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Total</h2>
                    <p>765</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>New</h2>
                    <p>142</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Unfollowed</h2>
                    <p>58</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap_text_prev">
              <h3>Detail</h3>
              <div class="row">
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Friend</h2>
                    <p>2,089</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Fans</h2>
                    <p>11,289</p>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="wrap_resume_insight">
                    <h2>Not Folback</h2>
                    <p>90</p>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-card
        class="mb-0 shadow-0"
        body-class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap"
      >
        <div class="d-flex align-items-center mb-0 mb-md-0">
          <div>
            <h5 class="font-weight-bold mb-0">Best Post</h5>
          </div>
        </div>
        <div class="d-flex align-items-center mb-3 mb-md-0">
          <div class="ml-3">
            <div class="input-group">
              <span class="input-group-prepend">
                <span class="input-group-text"><i class="icon-calendar22"></i></span>
              </span>
              <date-range-picker
                class="form-control"
                control-container-class=""
                opens="left"
                append-to-body
                :date-range="dateRange"
                style="min-width: 180px"
              />
            </div>
          </div>
        </div>
      </b-card>
      <b-tabs nav-class="bg_tab nav-tabs-top" justified>
        <b-tab title="Facebook">
          <!-- Marketing campaigns -->
          <div class="card_mention">
            <div class="row">
              <div class="col-md-2">
                <div class="card_thumbnail">
                  <img class="img-responsive" src="https://placehold.it/200x150" />
                </div>
                <div class="d-flex align-items-center">
                </div>
              </div>
              <div class="col-md-8">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebook.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                  <div class="total_share mt-3">
                    <ul>
                      <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-bubble2"></i> 1,383 </a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-2">
                <div class="card_thumbnail">
                  <img class="img-responsive" src="https://placehold.it/200x150" />
                </div>
                <div class="d-flex align-items-center">
                </div>
              </div>
              <div class="col-md-8">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebook.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                  <div class="total_share mt-3">
                    <ul>
                      <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-bubble2"></i> 1,383 </a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-2">
                <div class="card_thumbnail">
                  <img class="img-responsive" src="https://placehold.it/200x150" />
                </div>
                <div class="d-flex align-items-center">
                </div>
              </div>
              <div class="col-md-8">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebook.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                  <div class="total_share mt-3">
                    <ul>
                      <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-bubble2"></i> 1,383 </a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Twitter">
          <div class="card_mention">
            <div class="row">
              <div class="col-md-2">
                <div class="card_thumbnail">
                  <img class="img-responsive" src="https://placehold.it/200x150" />
                </div>
                <div class="d-flex align-items-center">
                </div>
              </div>
              <div class="col-md-8">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebbok.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                  <div class="total_share mt-3">
                    <ul>
                      <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-bubble2"></i> 1,383 </a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-2">
                <div class="card_thumbnail">
                  <img class="img-responsive" src="https://placehold.it/200x150" />
                </div>
                <div class="d-flex align-items-center">
                </div>
              </div>
              <div class="col-md-8">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebbok.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                  <div class="total_share mt-3">
                    <ul>
                      <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-bubble2"></i> 1,383 </a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card_mention">
            <div class="row">
              <div class="col-md-2">
                <div class="card_thumbnail">
                  <img class="img-responsive" src="https://placehold.it/200x150" />
                </div>
                <div class="d-flex align-items-center">
                </div>
              </div>
              <div class="col-md-8">
                <div class="card_content">
                  <h3><a href="">lamaran sebagai iOS Developer (3284527)</a></h3>
                  <h4><a href="">facebbok.com</a></h4>
                  <p>
                    lamaran sebagai iOS Developer (3284527) di WEBARQ (PT Web Architect Technology)! Cari lowongan
                    kerja
                  </p>
                  <div class="total_share mt-3">
                    <ul>
                      <li><a href=""><i class="icon-share3"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-thumbs-up2"></i> 1,383 </a></li>
                      <li><a href=""><i class="icon-bubble2"></i> 1,383 </a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-md-2">
                <a href="#" class="text-default">
                  <span class="text-muted"><i class="icon-calendar mr-2"></i> 21 Sep 2020, 15:00</span>
                </a>
                <div class="row">
                  <div class="col-md-12 mt-3">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab title="Instagram"></b-tab>
      </b-tabs>
      <b-card no-body>
        <b-card-body class="d-md-flex align-items-md-center justify-content-md-between flex-md-wrap">
          <div class="d-flex align-items-center mb-0 mb-md-0">
            <div>
              <h5 class="font-weight-bold mb-0">Best Time</h5>
            </div>
          </div>
          <div class="d-flex align-items-center mb-3 mb-md-0">
            <div class="ml-3">
              <b-form-select :options="mediaOptions" v-model="selMedia" />
            </div>
          </div>
        </b-card-body>
        <b-card-body>
          <div class="chart-container">
            <v-chart class="chart has-fixed-height" :option="bestTimeOption" />
          </div>
        </b-card-body>
      </b-card>

      <b-card no-body>
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Engagement</b-card-title>
        </template>
        <b-table
          :fields="socmedEngagementFields"
          :items="socmedEngagementDatas"
          bordered
          responsive
          :per-page="5"
          :current-page="socmedEngagementPage"
        >
          <template #cell(num)="data">
            {{ data.index += 1 + (socmedEngagementPage - 1)*5  }}
          </template>
          <template #cell(date)="data">
            <strong>{{ data.value | moment('ll') }}</strong>
          </template>
          <template #cell(post)="data">
            <span
              :class="`text-${data.value.type == 'up' ? 'success-600' : 'danger'} mr-2`"
            >
              <i
                :class="`icon-stats-${data.value.type == 'up' ? 'growth2' : 'decline2'} mr-2`" /> 
              {{ data.value.value }}
            </span>
          </template>
          <template #cell(reach)="data">
            <span
              :class="`text-${data.value.type == 'up' ? 'success-600' : 'danger'} mr-2`"
            >
              <i
                :class="`icon-stats-${data.value.type == 'up' ? 'growth2' : 'decline2'} mr-2`" /> 
              {{ data.value.value }}
            </span>
          </template>
          <template #cell(like)="data">
            <span
              :class="`text-${data.value.type == 'up' ? 'success-600' : 'danger'} mr-2`"
            >
              <i
                :class="`icon-stats-${data.value.type == 'up' ? 'growth2' : 'decline2'} mr-2`" /> 
              {{ data.value.value }}
            </span>
          </template>
          <template #cell(comment)="data">
            <span
              :class="`text-${data.value.type == 'up' ? 'success-600' : 'danger'} mr-2`"
            >
              <i
                :class="`icon-stats-${data.value.type == 'up' ? 'growth2' : 'decline2'} mr-2`" /> 
              {{ data.value.value }}
            </span>
          </template>
          <template #cell(share)="data">
            <span
              :class="`text-${data.value.type == 'up' ? 'success-600' : 'danger'} mr-2`"
            >
              <i
                :class="`icon-stats-${data.value.type == 'up' ? 'growth2' : 'decline2'} mr-2`" /> 
              {{ data.value.value }}
            </span>
          </template>
        </b-table>
        <template #footer>
          <b-pagination
            :per-page="5"
            :total-rows="socmedEngagementDatas.length"
            class="pagination-flat"
            v-model="socmedEngagementPage"
            hide-goto-end-buttons
            align="end"
          >
            <template #prev-text>&larr; &nbsp; Prev</template>
            <template #next-text>Next &nbsp; &rarr;</template>
          </b-pagination>
        </template>
      </b-card>
      
      <b-card>
        <template #header>
          <b-card-title title-tag="h6" class="font-weight-bold">Visitor</b-card-title>
          <div class="chart-container">
            <v-chart class="chart has-fixed-height" :option="visitorOptions" />
          </div>
        </template>
      </b-card>
      <b-row>
        <b-col md="6">
          <b-card>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Engagement</b-card-title>
            </template>
            <div class="chart-container">
              <v-chart class="chart has-fixed-height" :option="engagementOptions" />
            </div>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card>
            <template #header>
              <b-card-title title-tag="h6" class="font-weight-bold">Location</b-card-title>
            </template>
            <div class="chart-container">
              <v-chart class="chart has-fixed-height" :option="mentionLocationOptions" />
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <bo-footer></bo-footer>
  </div>
</template>
<script>
import chartDatas from '@/dummies/chartDatas'
import tableDatas from '@/dummies/tableDatas'
import {
  use
} from "echarts/core";
import {
  CanvasRenderer
} from "echarts/renderers";
import {
  PieChart,
  LineChart,
  BarChart,
  ScatterChart,
  RadarChart,
} from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
} from "echarts/components";
import VChart from 'vue-echarts'
import GlobalVue from '../../libs/Global.vue';

use([
  CanvasRenderer,
  PieChart,
  LineChart,
  BarChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
  ScatterChart,
  RadarChart,
])

export default {
  extends: GlobalVue,
  components: {
    VChart,
  },
  data() {
    return {
      dateRange: {},
      bestTimeOption: chartDatas.bestTimeOptions, 
      visitorOptions: chartDatas.visitorOptions, 
      engagementOptions: chartDatas.engagementOptions,
      mentionLocationOptions: chartDatas.mentionLocationOptions,
      socmedEngagementFields: tableDatas.socmedEngagementFields,
      socmedEngagementDatas: tableDatas.socmedEngagementDatas,
      socmedEngagementPage: 1,
      mediaOptions: [
        { text: 'Facebook', value: 'fb' },
        { text: 'Twitter', value: 'tw' },
        { text: 'Instagram', value: 'ig' },
      ],
      selMedia: 'fb'
    }
  },
}
</script>
